
 .row-2 {
  height: 100%;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
}
 .divC2 {
  width: 100%;
  border-bottom: 1px solid #3e3e3e;
  height: 11%;
  margin: 0;
  padding-top: 3.5%;
  padding-bottom: 3.5%;


  padding-left: 10%;
}
 .newTemplateHeader {
  display: flex;
  height: 81%;
  justify-content: space-between;
  width: auto;
  padding-top: 2%;
  padding-left: 4.5%;
  padding: 20px;
}
 .templateClose {
  cursor: pointer;
  margin-top: -0.5%;
  margin-right: 3%;
}
 .divC9{
  height: 79%;
  width: 100%;
  padding-top: 0%;
  /* padding-left: 6%; */
  padding-bottom: 0;
  overflow-y: scroll;
}
 .content {
  margin: 0;
  padding: 0;
  /* border: 1px solid #3e3e3e; */
  height: 12.5%;
  padding-top: 1.5%;
  /* padding-left: 4.5%; */
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-family: Asap;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #1f1f1f;
}
 .content-1 {
  /* border: 1px solid #3e3e3e; */
  display: flex;
  padding-left: 8%;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  height: 100%;
  width: 56%;
}
 .content-2 {
  display: flex;
  padding-left: 5%;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  height: 100%;
  width: 52%;
}
.fiTypesInput {
  /* border: 1px solid blue; */
  margin-top: 2.8%;
  height: 62%;
  width: 80%;
}
.fiTypesInput1{
  height: 30px;
    margin: 0;
    /* padding-left: 5%; */
    display: flex;
    padding-right: 10px;
    width: 80%;
    margin-top: 5px;
    background: #ffffff;
    border: 1px solid black;
}

.fetchRangeErr{
  font-size: 12px;
    margin-left: 38px;
    
    color: red;
}

#fiTypes {
  height: 100%;
  width: 100%;
  border: 1px solid #3e3e3e;
  background: #ffffff;
  font-family: Asap;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #1f1f1f;
  box-sizing: border-box;
  border-radius: 2px;
  padding-left: 5%;
}

 .divC1 {
  /* border: 1px solid #3e3e3e; */
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
  height: 11%;
  margin: 0;
  padding-top: 1%;


  /* padding-left: 10%; */
}
 .save {
  width: 87%;
  height: 80%;
  background: #3a86ff;
  border-radius: 2px;
  border: 0px;
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
}
