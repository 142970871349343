.api {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5%;
  /* border: 1px solid red; */
  height: 98%;
}
.api .col-4 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2%;
  width: 100%;
  /* border: 1px solid red; */
  height: 25%;
}
.api .api-table {
  border: 1px solid #dddddd;
  width: 100%;
  height: 130px;
}
.api-tr {
  height: 30%;
  background: #d8d8d8;
}

.api tbody {
  height: 60%;
  /* border: 1px solid black; */
}
.api th:nth-child(1) {
  width: 50%;
  padding-left: 1%;
}

#api-tbody tr td:nth-child(1) {
  padding-left: 1%;
}
.apiButton {
  color: white;
  border: 0px solid #3579f6;
  background: #3579f6;
  width: 15%;
  height: 40px;
  border: 50%;
}
.api #api-action {
  display: flex;
  flex-direction: row;
  /* background: black; */
  height: 100px;
  align-items: center;
  /* border: 1px solid black; */
}
.api .copied {
  margin-left: 2%;
}
.api .col-8 {
  /* border: 1px solid black; */
  width: 85%;
  height: 55%;
}
.api .col-8 .info1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
  background: #dddddd;
  height: 30%;
}
.api .col-8 #info2 {
  display: flex;
  padding-left: 2.5%;
  padding-right: 2.5%;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  flex-direction: column;
  justify-content: center;
  justify-content: center;
  margin-top: 3%;
  /* border: 1px solid red; */
  background: #dddddd;
  /* height: 40%; */
}
