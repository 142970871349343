.selectBankDiv{
    width: 100%;
    background: #FFFFFF;
  /* border: 1px solid #E3E3E3; */
  box-sizing: border-box;
  height: max-content;
  /* padding-top: 35px; */
  padding-bottom: 25px;
  /* padding-left: 20px;
  padding-right: 20px; */
  /* overflow-x: hidden;
                overflow-y: auto; */
  
  }
  .selectBankDivActive{
    width: 100%;
    background: #FFFFFF;
  /* border: 1px solid #E3E3E3; */
  box-sizing: border-box;
  height: max-content;
  /* padding-top: 35px; */
  
  padding-bottom: 10px;
  border-bottom: none;
  /* padding-left: 20px;
  padding-right: 20px; */
  /* overflow-x: hidden;
                overflow-y: auto; */
                scrollbar-width: none;
  
  }
  .selectBankDiv::-webkit-scrollbar {
    display: none;
  }

  .selectBankText{
    font-family: 'Asap';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  overflow-x: hidden;
                overflow-y: hidden;
  
  
  
  color: #1E1E1E;
  width: 100%;
  display: flex;
  justify-content: flex-start
  ;
  align-items: center;
  /* padding-left: 5px; */
  /* padding-bottom: 10px; */
  }

  .DropdDownWrapper{
    width: 100%;
    }
    .dropdownPdf{
      width: 100%;
      /* border: 1px solid; */
      user-select: none;
      position: relative;
      height: fit-content;
      /* overflow: auto */
      margin-top: 10px;
      padding:0;
      border: none
    ;
            }
    
    .dropdownButton{
      width: inherit;
      font-family: 'Asap';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height */
    /* padding-top: 20px;
    padding-bottom: 20px; */
    padding-left: 10px;
    padding-right: 10px;
    background: #FFFFFF;
    border: 1px solid #060606;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: #626262;
    overflow-x: hidden;
                  overflow-y: hidden;
                  height: 48px;
                  display: flex;
                  align-items: center;
                  /* opacity:  #3A86FF 5px solid; */
    
    }
    .dropdownButtonSuccess{
      width: inherit;
      font-family: 'Asap';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height */
    /* padding-top: 20px;
    padding-bottom: 20px; */
    padding-left: 10px;
    padding-right: 10px;
    background: #FFFFFF;
    border:1.5px solid #3A86FF;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: #626262;
    overflow-x: hidden;
                  overflow-y: hidden;
                  height: 48px;
                  display: flex;
                  align-items: center;
                  /* opacity:  #3A86FF 5px solid; */
    
    }
    .dropdownButtonSuccess{
      width: inherit;
      font-family: 'Asap';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height */
    /* padding-top: 20px;
    padding-bottom: 20px; */
    padding-left: 10px;
    padding-right: 10px;
    background: #FFFFFF;
    border:1.5px solid #3A86FF;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: #626262;
    overflow-x: hidden;
                  overflow-y: hidden;
                  height: 48px;
                  display: flex;
                  align-items: center;
                  /* opacity:  #3A86FF 5px solid; */
    
    }
    .dropdownButtondisabled{
      width: inherit;
      font-family: 'Asap';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height */
    /* padding-top: 20px;
    padding-bottom: 20px; */
    padding-left: 10px;
    padding-right: 10px;
    background: #FFFFFF;
    border: 1px solid #060606;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: #CACACA;;
    height: 48px;
    display: flex;
    align-items: center;
    }

    .isActiveDropdown{
        /* height: 690px; */
        height: 88%;
          width: 99%;
          /* overflow-x: hidden;
        overflow-y: auto; */
        display: flex;
        flex: 1;
        /* border: 1px solid red; */
        background-color: white!important;
        
        
        }
        .dropdownContent{
          background: #FFFFFF;
        /* border-bottom: 0.5px solid #AEAEAE; */
        /* border-left: none;
        border-right: none; */
        box-sizing: border-box;
        border-radius: 2px;
        width: 100%;
        height: 100%;
        margin-top: 5px;
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 20px;
        
        
                      /* border: 1px solid; */
                      overflow-x: hidden;
                      overflow-y: auto;
                      scrollbar-width: none;
                      /* position: absolute */
        }
        
        
        
        
        
        .dropdownContent::-webkit-scrollbar {
          display: none;
        }
        .bankIconClass{
        height: 18px;
        width: 18px;
        }
        
        .deleteButton{
        width: 42.45px;
        height: 42.45px;
        display: flex;
        align-items: center;
        justify-content: center;
        float: right;
        background: #FFF1F4;
        border-radius: 3px;
        border: none;
        
        }
        .dropdownItem{
          /* border: 0.5px solid #AEAEAE; */
          /* border-top: 0.5px solid #AEAEAE ; */
          border-bottom: 0.5px solid #AEAEAE;
          width: auto;
          padding: 13px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-family: 'Asap';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        /* identical to box height */
        -webkit-transform: translate3d(0,0,0) !important;
        transform: translate3d(0,0,0) !important;
        
        
        color: #000000;
        
        
        }
        .dropdownItem:last-child {
        border: none;
        }
        
        .dropdownItem:first-child {
        border-top: 1px solid #DCDCDC;
        }
        
        .listDiv{
          margin-left: 20px;
        }
        
        .listDivIncome{
          margin-left: 10px;
          font-family: 'Asap';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
          
          color: #333232;
          
        }
        
        .bankStatementDiv{
          width: 100%;
          display: flex;
          padding: 2px;
          align-items: center;
          justify-content: flex-start;
          /* border: 1px solid red; */
        }
        
        .changeButton{
        width: 92px;
        height: 46px;
        /* left: 252px;
        top: 298px; */
        
        border: 1.5px solid #3A86FF;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Asap';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        /* identical to box height */
        background: #FFFFFF;;
        
        color: #3A86FF;
        float: right;
        }
        
        .uploadDiv{
          width: 100%;
          /* height: fit-content; */
          height: 48px;
          /* border: 1px solid red; */
          margin-top: 15px;
          /* align-items: flex-start; */
          
        }
        
        .uploadDivChange{
          width: 100%;
          /* height: fit-content; */
          height: 48px;
          /* border: 1px solid red; */
          margin-top: 15px;
          /* display: flex; */
          /* align-items: flex-start; */
          /* justify-/content: space-between; */
          
          
        }
        .inputClass{
            /* background: url("https://material.io/icons/static/images/icons-180x180.png") no-repeat 8px center; */
            width:100%;
            /* border: solid blue 2px; */
            /* float: left; */
            height: 46px;
            overflow:hidden;
            color: rgba(0, 0, 0, 0);
            cursor: pointer;
            /* position: absolute; */
            /* top: 0;  */
          /* bottom: 0; */
          left: 31%; 
          right: 100%;
          z-index: 1;
          /* width:max-content; */
          opacity: 0;
          
          }
          
          .inputClassActive{
            /* background: url("https://material.io/icons/static/images/icons-180x180.png") no-repeat 8px center; */
            width:100%;
            border: 1px solid #3A86FF;
            /* float: left; */
            height: 46px;
            overflow:hidden;
            color: rgba(0, 0, 0, 0);
            cursor: pointer;
            /* position: absolute; */
            /* top: 0;  */
          /* bottom: 0; */
          left: 31%; 
          right: 100%;
          z-index: 1;
          /* width:max-content; */
          opacity: 0;
          
          }
          
          .inputClassChange{
            /* background: url("https://material.io/icons/static/images/icons-180x180.png") no-repeat 8px center; */
            width:75%;
            /* border: solid blue 2px; */
            /* float: left; */
            height: 46px;
            overflow:hidden;
            color: rgba(0, 0, 0, 0);
            cursor: pointer;
            /* position: absolute; */
            /* top: 0;  */
          /* bottom: 0; */
          left: 31%; 
          right: 100%;
          z-index: 1;
          /* width:max-content; */
          opacity: 0;
          
          }
          
          .inputClassActiveChange{
            /* background: url("https://material.io/icons/static/images/icons-180x180.png") no-repeat 8px center; */
            width:75%;
            border: 1.5px solid #3A86FF;;
            /* float: left; */
            height: 46px;
            overflow:hidden;
            color: rgba(0, 0, 0, 0);
            cursor: pointer;
            /* position: absolute; */
            /* top: 0;  */
          /* bottom: 0; */
          left: 31%; 
          right: 100%;
          z-index: 1;
          /* width:max-content; */
          border-radius: 4px;
          /* opacity: 0; */
          display: none;
          
          }
          
          
          .labelAndInputDiv{
            /* width: 70%; */
            background: #FFFFFF;
          border: 1px solid #CACACA;
          box-sizing: border-box;
          border-radius: 4px;
          width: 100%;
          height: 46px;
          float: left;
          display: flex;
          flex-direction: column;
          }
          
          .labelAndInputDivActive{
            /* width: 70%; */
            background: #FFFFFF;
          border: 1px solid #060606;
          box-sizing: border-box;
          border-radius: 4px;
          width: 100%;
          height: 46px;
          float: left;
          display: flex;
          flex-direction: column;
          }
          
          .labelAndInputDiv1{
            /* width: 70%; */
            background: #FFFFFF;
          border: 1px solid #CACACA;
          box-sizing: border-box;
          border-radius: 4px;
          width: 100%;
          height: 46px;
          float: left;
          display: flex;
          flex-direction: column;
          }
          
          .labelAndInputDivChange{
            /* width: 70%; */
            background: #FFFFFF;
          border: 1px solid #CACACA;
          box-sizing: border-box;
          border-radius: 4px;
          width: 70%;
          height: 46px;
          float: left;
          display: flex;
          flex-direction: column;
          }
          
          .labelAndInputDivChangeUploaded{
            /* width: 70%; */
            background: #FFFFFF;
          border: 1px solid #CACACA;
          box-sizing: border-box;
          border-radius: 4px;
          width: 85%;
          height: 46px;
          float: left;
          display: flex;
          flex-direction: column;
          }
          
          .aFile {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            /* border: 1px solid red; */
            /* height: ; */
            /* z-index: 10; */
            /* position: absolute; */
            height: 190px;
            display: flex;
            align-items: center;
            padding-left: 15px ;
            padding-right: 15px;
            display: flex;
            flex-direction: row;
          }
          
          .aFileChange {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            /* border: 1px solid red; */
            /* height: ; */
            /* z-index: 10; */
            /* position: absolute; */
            height: 190px;
            display: flex;
            align-items: center;
            padding-left: 15px ;
            padding-right: 15px;
            display: flex;
            flex-direction: row;
          }
          
          
          
          .aFileChangeUploaded {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            /* border: 1px solid red; */
            /* height: ; */
            /* z-index: 10; */
            /* position: absolute; */
            height: 190px;
            display: flex;
            align-items: center;
            padding-left: 15px ;
            padding-right: 15px;
            display: flex;
            flex-direction: row;
          }
           .inputClassActive::-webkit-file-upload-button {
            visibility: none;
          }
          
          .inputClass::-webkit-file-upload-button {
          visibility: none;
          /* opacity: 0; */
          }
          
          .inputClassActiveChange::-webkit-file-upload-button {
          visibility: none;
          /* opacity: 0; */
          }
          
          .inputClassChange::-webkit-file-upload-button {
          visibility: none;
          /* opacity: 0; */
          }
          
          
          .uploadButton{
           /* width: 100%; */
           /* float: right; */
           /* border: 1px solid; */
           border: 1px solid #3A86FF;
          
          font-family: 'Asap';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          /* identical to box height */
          
          color: white;
          /* color: #3A86FF; */
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 15px;
          width: 91px;
          height: 31px;
          
          
          background: #3A86FF;
          border-radius: 15.5px;
          }
          
          .uploadSuccessful{
           display: flex;
           margin-top: 10px;
           margin-left: 5px;
          }
          
          .uploadButtonDisabled{
          
          /* float: right; */
          /* border: 1px solid; */
          border: 1px solid  #B4B4B4;
          ;
          
          background-color: #B4B4B4;
          font-family: 'Asap';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          /* identical to box height */
          
          
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 15px;
          width: 91px;
          height: 31px;
          
          
          /* background: #3A86FF; */
          border-radius: 15.5px;
          }
          
          
          .uploadButton:hover{
           /* background-color: #3A86FF;
           color: white; */
           outline: none;
          }
          
          
          .uploadText{
           width:70%;
           margin-left: 10px;
           font-family: 'Asap';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          /* identical to box height */
          overflow: hidden;
            text-overflow: ellipsis;
          
          
          color: #CACACA;
          }
          
          .uploadTextUploaded{
          width:70%;
          margin-left: 10px;
          font-family: 'Asap';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          /* identical to box height */
          overflow: hidden;
           text-overflow: ellipsis;
          
          
          color: #181818;
          }
          
          .noteDiv{
           width: 100%;
           /* padding-top: 10px;
           padding-bottom: 10px; */
           font-family: 'Asap';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          /* identical to box height */
          height: fit-content;
          padding-top: 8px;
          
          color: #333333;
          /* border: 1px solid blue; */
          position: relative;
          margin-top:10px
          
          }
          
          .proceedButton{
            width: 25%;
          background: #3A86FF;
          border-radius: 4px;
          height: 40px;
          /* min-height: 52px; */
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'Asap';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 25px;
          /* identical to box height */
          
          text-align: center;
          
          color: #FFFFFF;
          
          margin-top: 20px;
          border: none;
          
          }        


          .addMore{
            width: 101px;
            height: 31px;
            /* left: 18px;
            top: 439px; */
            margin-top: 20px;
            background: #EBF3FF;
            border-radius: 15.5px;
            font-family: 'Asap';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            /* identical to box height */
            border: 1px solid #EBF3FF;
            
            
            color: #3A86FF;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            
            }
            
            .blueCircle{
            
            background: #3A86FF;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            }
            
            /* Firefox */
            .passwordinput[type=number] {
            -moz-appearance: textfield;
            }
            
            .passwordinput:focus{
            outline: none;
            }

            .proceedButtonDisabled{
                width: 25%;
                background: #B4B4B4;
                border-radius: 4px;
                height: 40px;
                /* min-height: 52px; */
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: 'Asap';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 25px;
                /* identical to box height */
                
                text-align: center;
                
                color: #FFFFFF;
                
                margin-top: 20px;
                border: none;
                }

                .processingText{
                    font-family: "Asap";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 26px;
                    line-height: 29px;
                    /* identical to box height */
                    
                    
                    color: #212121;
                    margin-top: 10px;
                    /* border: 1px solid red; */
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    display: flex
                    }
                    
                    
                    .processingSmallText{
                    font-family: 'Asap';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    /* identical to box height */
                    
                    text-align: center;
                    
                    color: #8F8F8F;
                    margin-top: 10px;
                    }

                    
                    .form-group.required .control-label:after {
                        content:"*";
                        color:red;
                      }

                      .checkbox{
                        height: 16.75px;
                        width: 16.75px;
                        background-color: #555555;
                        border: 4px solid black;
                        margin-right: 5px;
                      }
                      
                      .checkboxDiv{
                        /* border: 1px solid; */
                        display: flex;
                        
                      }
                      
.passwordinput{
    width: 100%;
  height: 38px;
  /* left: 21px;
  top: 442px; */
  
  background: #F3F3F3;
  border-radius: 2px;
  border: none;
  margin-top: 12px;
  padding:10px;
  font-family: 'Asap';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */
  
  
  color: #333333;
  }
  
  .passwordinput::-webkit-outer-spin-button,
  .passwordinput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  }
  