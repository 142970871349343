#updateFiu {
  margin: 0;
  padding: 2.5% 2% 0 2.5%;
}
#container {
  height: 100%;
  width: 100%;
  padding: 0%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#container h4 {
  font-size: 20px;
  color: blue;
}
.profile {
  height: 70%;
  width: 100%;
  padding: 5%;
  padding-top: 3%;
  background-color: rgb(241, 239, 239);
}
.profile h1 {
  font-size: 15px;
  margin-bottom: 10px;
}

.fiu_logo {
  display: flex;
  margin: 0;
  height: 70%;
  width: 100%;
}
.spinnerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: inherit;
}

.image-upload {
  aspect-ratio: 1;
  height: 200px !important;
  padding: 5px;
  border: 1px solid #c8c8c8;
  border-radius: 3px;
}

.image-upload {
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: rgb(191 187 187 / 77%);
  border-radius: 2px;
}

.image-upload:hover .overlay {
  opacity: 1;
}

.icon {
  color: white;
  cursor: pointer;
  position: absolute;
  top: 3%;
  left: 90%;
  padding: 3%;
  transform: translate(-50%, 0%);
  height: 30px;
  width: 30px;
  color: #c00000;
  border: 1px solid #787272;
  box-sizing: border-box;
  border-radius: 2px;
  text-shadow: 0 0 10px blue;
}

.overlay > input {
  display: none;
}

/* #image:hover{
    display: block;
    border: 2px solid black;
} */

#upload-button {
  width: 16%;
  height: 14%;
  padding: 9px;
  margin-left: 19px;
  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  cursor: pointer;
  border: 2px solid #3a86ff;
  color: #3a86ff;
  border-radius: 3px;
}
#upload-button1 {
  width: 15%;
  height: 14%;
  padding: 9px;
  margin-left: 19px;
  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  cursor: pointer;
  border: 2px solid #3a86ff;
  color: #3a86ff;
  border-radius: 3px;
}

.company_name {
  width: 100%;
  margin-top: 10px;
}

.company_name input {
  width: 200px;
  /* height: 35px; */
  font-size: 14px;
  padding-left: 15px;
}

.profile span1 {
  font-size: 10px;
  color: rgb(255, 7, 7);
  font-weight: lighter;
}

.profile span2 {
  font-size: 10px;
  color: grey;
  font-weight: lighter;
}

.upload_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 10%;
}

.upload {
  text-decoration: none;
}

.upload_button {
  display: flex;
  /* flex-direction: row; */
  /* align-items: center; */
  justify-content: center;
  /* align-self: center; */
  width: 150px;
  height: 14%;
  padding: 9px;
  margin-left: 19px;
  color: #fff;
  background-color: #3a86ff;
  cursor: pointer;
  border-radius: 3px;
}

.upload_disable_button {
  display: flex;
  /* flex-direction: row; */
  /* align-items: center; */
  justify-content: center;
  /* align-self: center; */
  width: 150px;
  height: 14%;
  padding: 9px;
  margin-left: 19px;
  color: #fff;
  background-color: #929292;
  cursor: pointer;
  border-radius: 3px;
}

.upload_button:hover {
  color: #fff;
  background: #3679e6;
}

.upload_icon {
  width: 22px;
  height: 22px;
}

.upload_text {
  margin: 0;
  padding: 0;
  margin-left: 13px;
  font-family: Asap;
  font-size: 17px;
  font-weight: 600;
  color: #fff;
}

.save_container {
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  /* width: 100%; */
  height: 10%;
}

.save_button_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 100%; */
  height: 10%;
}

.save {
  text-decoration: none;
}

.save_button {
  display: flex;
  /* flex-direction: row; */
  /* align-items: center; */
  justify-content: center;
  /* align-self: center; */
  width: 150px;
  height: 14%;
  padding: 9px;
  margin-left: 19px;
  color: #fff;
  background-color: #3a86ff;
  cursor: pointer;
  border-radius: 3px;
}

.save_disable_button {
  display: flex;
  /* flex-direction: row; */
  /* align-items: center; */
  justify-content: center;
  /* align-self: center; */
  width: 150px;
  height: 14%;
  padding: 9px;
  margin-left: 19px;
  color: #fff;
  background-color: #929292;
  cursor: pointer;
  border-radius: 3px;
}

.save_button:hover {
  color: #fff;
  background: #3679e6;
}

.save_icon {
  width: 22px;
  height: 22px;
}

.save_text {
  margin: 0;
  padding: 0;
  margin-left: 13px;
  font-family: Asap;
  font-size: 17px;
  font-weight: 600;
  color: #fff;
}
