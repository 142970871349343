#TryItNow {
  height: 100%;
  background: transparent;
  /* width: 100%; */
  /* justify-content: center;
  display: flex;
  align-items: center; */
  /* box-sizing: border-box;
    -webkit-box-sizing: border-box; */
  width: 100%;
  /* background: black; */
  /* padding-bottom: 30px;
    padding-top: 30px; */
  padding-left: 50px;
}

.GrayBackGroundDiv {
  width: 100%;
  height: 560px;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* border-radius: 30px; */
  /* margin-top: 20px; */
  /* border: 1px solid red; */
}

.iframeDiv {
  width: fit-content;
  height: 100%;
  /* border: 1px solid black; */
  /* margin-right: 1%; */
}

.iframeDiv::-webkit-scrollbar{
  display: none;
}
.demonstrateDiv {
  width: 40%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 100px;
  padding-bottom: 3px;
}

.stepsDiv {
  width: 100%;
  height: 50px;
  background: #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height */

  color: #000000;
}

.stepTextDiv {
  display: flex;
  width: 100%;
  /* height: 100%; */
  height: 92%;
  flex: 1;
  /* border: 1px solid black; */
  flex-direction: column;
  overflow-y: scroll;
}

.stepTextDiv::-webkit-scrollbar {
  width: 8px;
}

.stepTextDiv::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.stepTextDiv::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  /* height: 10px; */
}

.allstepsDiv {
  width: 100%;
  height: fit-content;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.shadowBottom {
  height: 20px;
  position: absolute;
  background-image: radial-gradient(
    farthest-side at 50% 100%,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  display: none;
}
.blueTick {
  margin-top: 1px;
}

.stepTextContainer {
  width: 100%;
  height: fit-content;
  /* border:1px solid red; */
  /* padding-top:10px; */
  /* padding-bottom: 10px; */
  padding-left: 10px;
}

.loanBigText {
  width: fit-content;
  height: fit-content;
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;

  color: #1c1c1c;
}

.loanSmallText {
  width: fit-content;
  height: fit-content;
  font-family: Asap;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #1c1c1c;
  padding-top: 10px;
}

.force-mobile::-webkit-scrollbar {
  display: none;
}

.force-mobile {
  width: 431px;
  z-index: 100;
  /* max-width: 750px; */
  height: 820px;
  /* margin: 0 auto; */
  border-style: solid;
  border-color: inherit;
  border-width: 0px;
  -webkit-transform: scale(0.57);
  -webkit-transform-origin: 0 0;
  /* display: block; */
  /* border-radius: 2px; */
  position: relative;
  margin-left: 16px;
  overflow: hidden;
  margin-top: 29px;
}

.MobileClass {
  width: 260px;
  height: 530px;
  z-index: 20;
  position: absolute;
  /* border: 1px solid red; */
  margin-left: 10px;
}

.MobileClass::-webkit-scrollbar{
  display: none;
}


@media screen and (min-width: 1400px) and (max-width: 1520px) {
  .GrayBackGroundDiv {
    height: 610px;
  }
  .MobileClass {
    z-index: 100;
    height: 610px;
    /* width: 320px; */
    width: 300px;
  }
  .MobileClass::-webkit-scrollbar{
    display: none;
  }
  .force-mobile {
    height: 820px;
    margin-left: 17px;
    width: 428px;
    /* margin-right: -142px; */
    -webkit-transform: scale(0.66);
    -webkit-transform-origin: 0 0;
    margin-top: 30px;
  }
  .force-mobile::-webkit-scrollbar {
    display: none;
  }
  .iframeDiv {
    margin-right: 5%;
  }
  .iframeDiv::-webkit-scrollbar{
    display: none;
  }
}
@media screen and (min-width: 1520px) {
  .GrayBackGroundDiv {
    height: 690px;
  }
  .MobileClass {
    height: 630px;
    /* width: 320px; */
    width: 320px;
  }
  .MobileClass::-webkit-scrollbar{
    display: none;
  }
  .force-mobile {
    height: 810px;
    margin-left: 18px;
    width: 436px;
    /* margin-right: -132px; */
    -webkit-transform: scale(0.69);
    -webkit-transform-origin: 0 0;
    margin-top: 32px;
  }
  .force-mobile::-webkit-scrollbar {
    display: none;
  }
  .iframeDiv {
    margin-right: 7%;
  }
  .iframeDiv::-webkit-scrollbar{
    display: none;
  }
}
