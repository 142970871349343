.sidebar_main_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1.5px solid #bbbbbb;
  word-break: break-all;
}

.sidebar_container_fiu {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.sidebar_container_fiu_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
}

.sidebar_container_fiu_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 3px;
  background-color: #e3e3e3;
}

.sidebar_container_fiu_icon {
  width: 40px;
}

.sidebar_container_fiu_name_email_container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.sidebar_container_fiu_name {
  margin: 0;
  padding: 0;
  font-family: Asap;
  font-size: 14px;
  font-weight: 600;
  color: #272727;
}

.sidebar_container_fiu_name .tool_tip_name {
  visibility: hidden;
  padding: 4px;
  background-color: rgb(197, 197, 197);
  color: rgb(73, 73, 73);
  text-align: center;
  border-radius: 2px;
  position: absolute;
  z-index: 1;
}

.sidebar_container_fiu_email {
  margin: 0;
  padding: 0;
  font-family: Asap;
  font-size: 13px;
  font-weight: 500;
  color: #929191;
}

.sidebar_container_fiu_email .tool_tip_email {
  visibility: hidden;
  padding: 4px;
  background-color: rgb(73, 73, 73);
  color: rgb(238, 238, 238);
  text-align: center;
  border-radius: 2px;
  position: absolute;
  z-index: 1;
}

.sidebar_divider {
  width: 100%;
  height: 0.13%;
  background-color: #bbbbbb;
}

.sidebar_container_rest {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 77%;
}

.sidebar_container_rest_heading {
  margin: 0;
  padding: 0;
  margin-top: 18px;
  margin-bottom: 10px;
  padding-left: 20px;
  font-family: Asap;
  font-size: 19px;
  font-weight: 600;
  color: #000;
}

.sidebar_container_rest_row_inactive {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-top: 10px; */
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  cursor: pointer;
}

.sidebar_container_rest_row_inactive:hover {
  background-color: #e9f2ff;
}

.sidebar_container_rest_row_active {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-top: 10px; */
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  cursor: pointer;
  background-color: #e9f2ff;
}

.sidebar_container_rest_icon {
  width: 25px;
  height: 25px;
}

.supportIcon {
  width: 25px;
  height: 25px;
}

.supportIconFaded {
  width: 25px;
  height: 25px;
}

.sidebar_container_rest_title_inactive {
  margin: 0;
  padding: 0;
  margin-left: 13px;
  font-family: Asap;
  font-size: 15px;
  color: #000;
}

.sidebar_container_rest_title_active {
  margin: 0;
  padding: 0;
  margin-left: 13px;
  font-family: Asap;
  font-size: 15px;
  font-weight: 600;
  color: #3a86ff;
}

.sidebar_container_rest_logout_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 10%;
}

.sidebar_nav_link_logout {
  text-decoration: none;
}

.sidebar_container_rest_logout_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 60%;
  padding: 15px;
  color: #fff;
  background-color: #3a86ff;
  cursor: pointer;
  border-radius: 3px;
}

.sidebar_container_rest_logout_button:hover {
  color: #fff;
  background: #3679e6;
}

.sidebar_container_rest_logout_icon {
  width: 22px;
  height: 22px;
}

.sidebar_container_rest_logout_text {
  margin: 0;
  padding: 0;
  margin-left: 13px;
  font-family: Asap;
  font-size: 17px;
  font-weight: 600;
  color: #fff;
}
