#support{
    height: 100%;
  background: transparent;
  /* width: 100%; */
  /* justify-content: center;
  display: flex;
  align-items: center; */
  /* box-sizing: border-box;
    -webkit-box-sizing: border-box; */
  width: 100%;
  /* background: black; */
  /* padding-bottom: 30px;
    padding-top: 30px; */
  /* padding-left: 50px; */
  /* border: 1px solid red; */
}

.supportDiv{
    height: inherit;
    width: inherit;
    /* border: 1px solid blue; */
    display: flex;
    align-items: center;
    flex-direction: column;
}

.imageDiv{
    width: fit-content;
    height: fit-content;
    /* border: 1px solid orange; */
    margin-top: 2%;
    justify-content: center;
}

.contactImage{
    width: 90%;
    height: 90%;
}
.contactDiv{
    width: 100%;
    height: 100%;
    padding-left: 28%;
    padding-right: 32%;
    /* border: 1px solid red; */
}

.formContainer{
    width: auto;
    height: auto;
    background: #FFFFFF;
border: 1px solid #C3C3C3;
border-radius: 6px;
padding: 20px;
}

.headingDiv{
    font-family: 'Asap';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 23px;
/* identical to box height */


color: #272727;
}

.formDiv{
    width: 100%;
    height: 100%;
    margin-top: 10px;
    /* border: 1px solid red; */
}

.subjectInput{
    height: 46px;
    width: 100%;
    background: #FFFFFF;
border: 1.5px solid #494949;
box-shadow: 0px 4px 4px rgba(242, 242, 242, 0.25);
border-radius: 4px;
padding:5px;

font-family: 'Asap';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 18px;

color: #272727;
}

.anotherSubjectInput{
    height: 46px;
    width: 100%;
    background: #FFFFFF;
border: 1.5px solid #494949;
box-shadow: 0px 4px 4px rgba(242, 242, 242, 0.25);
border-radius: 4px;
padding:5px;

font-family: 'Asap';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 18px;

color: #272727;
margin-top: 10px;
/* box-shadow: 0px 4px 4px rgba(242, 242, 242, 0.25);
border-radius: 4px; */
}

.textAreaClass{
    width: 100%;
height: 188px;
margin-top: 10px;
font-family: 'Asap';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 18px;

color: #272727;

background: #FFFFFF;
border: 1.5px solid #494949;
box-shadow: 0px 4px 4px rgba(242, 242, 242, 0.25);
border-radius: 4px;
padding:5px;
}

.button{
    width: 100%;
height: 46px;

margin-top: 15px;
background: #3A86FF;
box-shadow: 0px 4px 4px rgba(242, 242, 242, 0.25);
border-radius: 4px;
font-family: 'Asap';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 23px;
/* identical to box height */
display: flex;
align-items: center;
justify-content: center;


color: #FFFFFF;
outline: none;
cursor: pointer;
}
.buttonDisabled{
    width: 100%;
height: 46px;

margin-top: 15px;
background: #C3C3C3;
box-shadow: 0px 4px 4px rgba(242, 242, 242, 0.25);
border-radius: 4px;
font-family: 'Asap';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 23px;
/* identical to box height */
display: flex;
align-items: center;
justify-content: center;


color: #FFFFFF;
cursor:not-allowed;
/* outline: none; */
}
.contactUsDiv{
    width: auto;
    height: auto;
    background: #FFFFFF;
border: 1px solid #C3C3C3;
border-radius: 6px;
padding: 20px;
    margin-top: 20px;
}

.emailMobilediv{
    margin-top: 10px;
    width: 80%;
    display: flex;
    justify-content: space-between;
    /* border: 1px solid red; */
}

.mobileDiv{
    display: flex;
    flex-direction: row;
}

.emailDiv{
    display: flex;
    flex-direction: row;
    margin-left: 20px;

}

.imageIcon{
    width: 20px;
    height: 20px;

}

.contactText{
    font-family: 'Asap';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 18px;

color: #272727;
margin-left: 5px;
}