#Error-Page .container {
  height: 100%;
  width: 100%;
}

#Error-Page .row {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
}
