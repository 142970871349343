#customer_page {
  /* border: 2px solid yellow; */
  height: 100%;
  width: 100%;
}
#customer_page .cp {
  margin: 0%;
  padding: 0;
  /* border: 0.5px solid blue; */
  height: 100%;
}
#customer_page .row:nth-child(1) {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0%;

  /* border: 0.5px red solid; */

  color: #42474f;
  display: flex;
  flex-direction: column;
}
#customer_page .col-3 {
  /* border: 1px green solid; */
  width: 100%;
  height: 13%;
  padding: 0;
  margin-top: 1%;
  display: flex;
  flex-direction: row;
}
.customerDiv {
  /* border: blue solid 1px; */
  width: 66%;
  margin: 0;
  padding: 0;
  margin-left: 1%;
}

#customer_page .customer-header {
  width: 100%;
  margin-top: 1%;
  margin-left: 1.5%;
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;

  line-height: 41px;
  /* border: black 1px solid; */
}

#customer_page .customer_content {
  /* border: red 1px solid; */
  margin-left: 1.6%;

  width: 100%;
  margin-top: 0.7%;
  font-family: Asap;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  color: #000000;
}
#customer_page .searchDiv {
  /* border: red 1px solid; */
  width: 29.5%;
  height: 100%;
  padding-left: 0%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: row;
}

#customer_page .searchBarDiv {
  /* border: 1px solid black; */
  margin-left: 1%;
  height: 40%;
  width: 69%;
}

#customer_page .searchBar {
  height: 100%;
  width: 100%;
  padding-left: 2%;
}
#customer_page .searchButtonDiv {
  /* border: 1px solid blue; */
  margin-left: 1%;
  height: 40%;
  width: 20%;
}
#customer_page .searchButton {
  height: 100%;
  width: 100%;
  background: #3d7bdf;
  color: #ffffff;
  border: 0px;
  transition: 550ms;
  cursor: pointer;
}
#customer_page .searchButton:hover {
  color: #ffffff;
  background: #3a86ff;
}
#customer_page .sortDiv {
  /* border: 1px solid black; */
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  padding-left: 1.5%;
}
#customer_page .sortHeader {
  margin-left: 3%;
  /* border: black 1px solid; */
  width: 30%;
  height: 30%;
}
#customer_page .sortDropDown {
  /* border: 1px solid black; */
  width: 45%;
  height: 35%;
}
#customer_page .col-8 {
  /* border: black 2px solid; */
  margin-top: 1%;
  margin-left: 1.5%;
  padding: 0;
  width: 95%;
  height: 74%;
}

#customer_page .custTable {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: scroll;
  /* border: black 2px solid; */
}
#customer_page .custTable::-webkit-scrollbar {
  display: none;
}
#customer_page table {
  /* border: 0.5px rgb(0, 0, 0) solid; */
  /* padding-bottom:; */
  width: 100%;
  height: auto;
  overflow: auto;
  table-layout: fixed;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
}
#customer_page thead {
  border: 0.5px solid #e6e6e6;
}
#customer_page thead tr th {
  height: 75px;
  position: sticky;
  top: 0;
  background: #ffff;
  vertical-align: middle;
  z-index: 5;
  padding-left: 2%;
  border: 0.5px solid #e6e6e6;

  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

#customer_page th:nth-child(1) {
  width: 15%;
  /* border: 1px solid black; */
}
#customer_page th:nth-child(2) {
  width: 18%;
  /* border: 1px solid black; */
  /* padding-left: 1.5%; */
}
#customer_page th:nth-child(3) {
  width: 20%;
  /* border: 1px solid black; */
}
#customer_page th:nth-child(4) {
  width: 15%;
  /* border: 1px solid black; */
}

#customer_page th:nth-child(5) {
  width: 15%;
  /* border: 1px solid black; */
}
#customer_page th:nth-child(6) {
  width: 25%;
  /* border: 1px solid black; */
}
#customer_page .preloader {
  /* border: 1px solid black; */
  /* height: 0%;
  width: 50%; */
  margin-left: 215%;
  margin-top: 50%;
}
#customer_page tbody tr td {
  height: 70px;
  /*padding-left: 2.5%; */
  /* padding-top: 2.5%; */
  vertical-align: middle;
  padding-left: 2%;
  border: 0.5px solid #e6e6e6;
  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  word-wrap: break-word;
  /* identical to box height */
  color: #1c1c1c;
}

#customer_page tbody tr td div::-webkit-scrollbar {
  display: none;
}
#customer_page tbody tr td:nth-child(6) {
  padding: 0;
}
#customer_page .fi_data {
  /* margin-top: 4%; */
  margin-left: 3%;
  height: 60%;
  width: 45%;
  background-color: #ffff;
  border: 1px solid #3a86ff;
  border-radius: 4px;

  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;

  color: #3a86ff;
  transition: 450ms;
}

#customer_page .data_req {
  margin-left: 2.5%;
  height: 60%;
  width: 45%;
  transition: 450ms;
  background-color: #ffff;
  border: 1px solid #3a86ff;
  border-radius: 4px;

  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;

  color: #3a86ff;
}
#customer_page .data_req:hover {
  color: #ffffff;
  background: #3a86ff;
}
#customer_page .fi_data:hover {
  color: #ffffff;
  background: #3a86ff;
}
#customer_page .preloader {
  /* border: 1px solid black; */
  /* height: 0%;
  width: 50%; */
  margin-left: 325%;
  margin-top: 70%;
}
#customer_page .col-1 {
  margin-top: 0.5%;
  width: 100%;
  height: 7%;
  /* border: 1px solid black; */
}
#customer_page .paginate {
  margin: 0;
  padding: 0;
  display: flex;
  /* border: 1px solid black; */
  height: 100%;
  width: 27%;
  /* margin-top: 1%; */
  margin-left: 33%;
}
.pagination {
  /* border: 1px solid red; */
  height: 100%;
  width: 100%;
}
.pageLink {
  border: 1px solid GREEN;
  margin-top: 1%;
  margin-left: 1.5%;
  margin-right: 1.5%;
  /* padding-left: 6%; */
  padding-top: 2.5%;
  /* vertical-align: middle; */
  height: 90%;
  width: 20%;

  text-align: center;
  background: #ffffff;
  border: 1px solid #dfe3e8;
  box-sizing: border-box;
  border-radius: 2px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  /* line-height: 20px; */
  /* or 143% */

  /* text-align: center; */

  /* Dark Grey / 400 */

  color: #212b36;
  transition: 450ms;
}
.pageLink:hover {
  cursor: pointer;
  background-color: white;
  color: #3a86ff;
  border: 1px solid #3a86ff;
}
.activate {
  margin-top: 1%;
  margin-left: 1.5%;
  margin-right: 1.5%;
  /* padding-left: 6%; */
  text-align: center;
  padding-top: 3%;
  border-radius: 2px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  /* vertical-align: middle; */
  height: 90%;
  width: 20%;

  cursor: pointer;
  background-color: white;
  color: #3a86ff;
  border: 1px solid #3a86ff;
}
.prevPost,
.nextPost {
  margin-left: 1.5%;
  margin-right: 1.5%;
  margin-top: 0.8%;
  height: 89%;
  width: 15%;
  padding-top: 00%;

  background: white;
  color: #c4cdd5;
  border: 1px solid #c4cdd5;
  box-sizing: border-box;
  border-radius: 2px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}

.pagination-item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
}
.disabled {
  margin-left: 1.5%;
  margin-right: 1.5%;
  margin-top: 0.8%;
  height: 89%;
  width: 15%;

  pointer-events: none;
  box-shadow: none;
  border: 1px #999 solid;
  color: #999;
  border-radius: 2px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}

.view-a {
  /* border: 1px solid; */
  margin-top: 0%;
  height: 40px;
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: #ffff;
  border: 1px solid #3a86ff;
  border-radius: 4px;
  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3a86ff;
  /* background: rgba(58, 134, 255, 0.11); */
  border-radius: 4px;
  transition: linear 0.3s;
}

.view-a:hover {
  color: #ffffff;
  background: #5596ff;
}
