#dropDownDiv {
  height: 100%;
  padding: 0;
  margin: 0;
  width: 100%;
  /* border: 1px solid #3e3e3e; */
  background: #ededed;
  border-radius: 2px;
  display: flex;
  align-items: center;
  font-family: Asap;
  font-style: normal;
  font-weight: normal;
  font-size: 15.5px;
  line-height: 16px;
  color: #1f1f1f;
  box-sizing: border-box;

  padding-left: 5%;
  cursor: pointer;

  position: relative;
}
#dropDownDiv .dropDownHeader {
  display: flex;
  align-items: center;
  width: 75%;
  justify-self: flex-start;
  order: 0;
  /* border: 1px solid;  */
  height: 100%;
}
.rotateimg1801 {
  transition: 500ms;
}
.rotateimg180 {
  transform: rotate(180deg);
  transition: 500ms;
}
#dropDownDiv .ArrowDropDown {
  height: 100%;
  width: 25%;
  display: flex;
  /* border: 1px solid; */

  order: 1;
}
#dropDownDiv .sortVal {
  /* border: 1px solid; */
  height: 100%;
  width: 75%;
  display: flex;
  align-items: center;
}
#dropDownDiv .tick {
  /* border: 1px solid; */
  height: 100%;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#dropDownDiv .dropD-content {
  top: 100%;

  margin: 0;
  padding: 0;
  height: 200%;
  z-index: 10;
  width: 100%;
  left: 0;
  position: absolute;
  background: #ededed;
  box-shadow: 0px 4px 4px rgba(142, 142, 142, 0.25);
  border-radius: 2px;
}

#dropDownDiv .dropD-content ul {
  margin: 0;
  /* overflow-y: scroll; */
  padding: 0;
  /* display: flex; */
  width: 100%;
  height: 30px;
  flex-direction: column;
  list-style-type: none;
  /* justify-content: center; */
  /* border: #1f1f1f 1px solid;  */
}

#dropDownDiv .dropD-content .DropDlist {
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  padding-left: 4.5%;

  display: flex;
  flex-direction: row;
  /* border: 1px solid black; */
}
#dropDownDiv .dropD-content .selectedSort {
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  padding-left: 4.5%;
  background: #e7edf7;
  border-radius: 2px;

  display: flex;
  flex-direction: row;
  /* border: 1px solid black; */
}
