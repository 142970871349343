

#updatePopup {
    position: absolute;
    z-index: 2;
    width: 62%;
    height: 89%;
    /* border: 1px solid green; */
    margin: 0;
    /* padding-left: 23%; */
    padding-top: 12%;
    padding-bottom: 0;
    background: rgba(70, 70, 70, 0.61);
}

#updatePopup .conatiner{
    height: 53%;
    width: 50%;
    background-color: white;
    margin-left: 25px;
    border-radius: 10px;
}

#updatePopup .deleteBackground {
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* height: 100%;
    width: 100%; */
    margin-top: 1%;
  }
  #updatePopup .deleteBinBg {
    height: 10%;
    width: 28%;
    margin-left: 34%;
    margin-top: 3%;
  }
  #updatePopup .deleteBin {
    margin-top: 10%;
    margin-left: 42%;
    height: 11%;
    width: 12%;
  }
  #updatePopup .deleteConfirmation {
    align-self: center;
    margin-top: 8%;
    font-weight: 500;
  }

.update_popup_button{
    margin: 0;
    text-align: center;
    margin-left: 0px;
}

.update_popup_button > .yes_button, .no_button{
    height: 50px;
    width: 80px;
}


#updatePopup .yes_button {
    background: #3a86ff;
    border-radius: 4px;
    font-family: Asap;
    font-style: normal;
    font-weight: 500;
    border: none;
    font-size: 18px;
    line-height: 16px;
    /* identical to box height */
  
    color: #ffffff;
  }
  
  #updatePopup .no_button {
    background: #ffffff;
    border: 1px solid #bf2d2d;
    box-sizing: border-box;
    border-radius: 4px;
    margin-left: 5%;
    font-family: Asap;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    /* identical to box height */
  
    color: #bf2d2d;
  }
  