#datarequest_page {
  /* border: 2px solid yellow; */
  height: 100%;
  width: 100%;
}
#datarequest_page .cp {
  margin: 0%;
  padding: 0;
  /* border: 0.5px solid blue; */
  height: 100%;
}
#datarequest_page .row:nth-child(1) {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0%;

  /* border: 0.5px red solid; */

  color: #42474f;
  display: flex;
  flex-direction: column;
}

#datarequest_page th:nth-child(5) {
  padding-left: 18px;
  /* text-align: center; */
  width: 140px;
  /* border:1px solid blue */
}

#datarequest_page td:nth-child(5) {
  padding: 0;
  text-align: center;
  width: fit-content;
  /* border:1px solid red */
}
/* #datarequest_page td:nth-child(2) {
  padding: 0;
  text-align: center;
}
 */

#datarequest_page .col-4 {
  /* border: 1px green solid; */
  width: 100%;
  height: 13%;
  padding: 0;
  margin-top: 1%;
}

#datarequest_page .customer-header {
  margin: 1%;
  margin-left: 1.5%;
  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  /* identical to box height */
  color: #42474f;
}

#datarequest_page .customers_link {
  text-decoration: none;
  color: #42474f;
}

#datarequest_page .customers_link.active {
  border: none;
}

#datarequest_page .customer-header span {
  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  /* identical to box height */
  color: #42474f;
}

#datarequest_page img {
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 12px;
  width: 8px;
}

#datarequest_page .datarequest-header {
  margin-left: 1.5%;
  margin-top: 2%;
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 16px;
}

#datarequest_page .customer_content {
  margin-left: 1.6%;
  margin-top: 1%;
  font-family: Asap;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  /* border: 1px solid; */
}

#datarequest_page .source-type {
  margin-top: 0%;
  padding: 3px;
  margin-left: 5%;
  height: 80%;
  width: 50%;
  background-color: #ffff;
  border: 1px solid #3a86ff;
  border-radius: 4px;
  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #3a86ff;
  /* background: rgba(58, 134, 255, 0.11); */
  border-radius: 4px;
  transition: linear 0.3s;
}
#datarequest_page .View {
  /* border: 1px solid; */
  margin-top: 0%;
  padding: 10px;
  /* width:140px; */
  padding-left: 15px;
  padding-right: 15px;
  background-color: #ffff;
  border: 1px solid #3a86ff;
  border-radius: 4px;
  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #3a86ff;
  /* background: rgba(58, 134, 255, 0.11); */
  border-radius: 4px;
  transition: linear 0.3s;
  height: 46px;
}
#datarequest_page .view-raw {
  /* border: 1px solid; */
  margin-top: 0%;
  padding: 10px;
  /* width:140px; */
  padding-left: 15px;
  padding-right: 15px;
  background-color: #ffff;
  border: 1px solid #3a86ff;
  border-radius: 4px;
  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #3a86ff;
  /* background: rgba(58, 134, 255, 0.11); */
  border-radius: 4px;
  transition: linear 0.3s;
  height: 46px;
}
#datarequest_page .view-raw:hover {
  color: #ffffff;
  background: #5596ff;
}

#datarequest_page .excel-raw {
  /* border: 1px solid; */
  margin-top: 0%;
  padding: 10px;
  /* width:140px; */
  padding-left: 15px;
  padding-right: 15px;
  background-color: #ffff;
  border: 1px solid #3a86ff;
  border-radius: 4px;
  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #3a86ff;
  /* background: rgba(58, 134, 255, 0.11); */
  border-radius: 4px;
  transition: linear 0.3s;
  height: 46px;
  margin-left: 10px;
}
#datarequest_page .excel-raw:hover {
  color: #ffffff;
  background: #5596ff;
}

#datarequest_page .View:hover {
  color: #ffffff;
  background: #5593f7;
}

#datarequest_page .view-raw {
  /* width: 50%; */
  /* margin-top: 5%; */
  /* margin-left: 10px; */
  /* height: 65%;
  width: 50%; */
}

#datarequest_page .col-8 {
  /* border: black 2px solid; */
  margin-top: 0%;
  margin-left: 1.5%;
  padding: 0;
  width: 95%;
  height: 80%;
}
#datarequest_page .custTable {
  margin-top: 4.5%;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: scroll;
  /* border: black 2px solid; */
}
#datarequest_page .custTable::-webkit-scrollbar {
  display: none;
}
#datarequest_page table {
  /* border: 0.5px rgb(0, 0, 0) solid; */
  height: auto;
  width: 100%;
  /* white-space: nowrap; */
  overflow: auto;
  table-layout: fixed;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
}
#datarequest_page thead {
  border: 0.5px solid #e6e6e6;
}
#datarequest_page thead tr th {
  height: 67px;
  position: sticky;
  /* width: 10%; */
  top: 0;

  background: rgb(255, 255, 255);
  vertical-align: middle;
  z-index: 5;
  /* padding-bottom: 2%; */
  padding-left: 2%;
  border: 0.5px solid #e6e6e6;
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

#datarequest_page th:nth-child(1) {
  width: 11%;
  /* border: 1px solid black; */
}
#datarequest_page th:nth-child(2) {
  width: 18%;
  /* border: 1px solid black; */
  /* padding-left: 1.5%; */
}
#datarequest_page th:nth-child(3) {
  width: 15%;
  /* border: 1px solid black; */
}
#datarequest_page th:nth-child(4) {
  width: 19%;

  /* border: 1px solid black; */
}

#datarequest_page tbody tr td:nth-child(5) {
  text-align: left;
  padding-left: 16px;
}

/* #datarequest_page tbody tr td:nth-child(2) {

} */

#datarequest_page tbody tr td {
  height: 62px;
  /* padding-left: 2.5%; */
  vertical-align: middle;
  padding-left: 2%;
  border: 0.5px solid #e6e6e6;
  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #1c1c1c;
  /* identical to box height */

  color: #1c1c1c;
}

#datarequest_page .modal-header {
  background: #f3f2f2;
  height: 10%;
}
.headingClass {
  font-size: 18px;
  font-weight: bold;
}
#datarequest_page .title {
  align-items: center;
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 0%;
}

#datarequest_page .indexTitle {
  padding-left: 3%;
  margin: 0;
  margin-top: 1.5%;

  background: #f0f0f0;
}

#datarequest_page .indexTitle1 {
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;

  color: #1f1f1f;
}

#datarequest_page #modal-title {
  margin: 0;
  margin-top: 1%;
  margin-left: 7%;
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  /* line-height: 18px; */
  color: #1f1f1f;
}
#datarequest_page .modal-p .value {
  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  width: 50%;
  color: #464646;
}

#datarequest_page .modal-p p {
  margin-top: 0;
  justify-content: space-between;
  display: flex;
  margin-bottom: 1rem;
  width: 85%;
}

#datarequest_page .modal-content {
  margin-top: 28%;
}

#datarequest_page .modal-p .attr {
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  /* font-weight: normal; */
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */
  color: #1f1f1f;
}

.jsonDownloadButton {
  width: fit-content;
  height: 48px;
  outline: none;
  background-color: white;
  padding: 10px;
  /* margin-top: 40px!important; */
  color: #3a86ff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #3a86ff;
  border-radius: 5px;
}
.jsonDownloadButton:hover {
  color: white;
  background-color: #3a86ff;
}

.transactionTab {
  height: 100%;
  width: 100%;
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.data_request_loader_conainer {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#datarequest_page .viewJson {
  /* margin-left: 5%; */
  border: none;
  background-color: #f3efec;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  outline: none;
  padding: 0;
}

#datarequest_page .Jsonimg {
  height: 20px;
  width: 20px;
}

.viewExcel {
  margin-left: 10px;
  border: none;
  background-color: #fcecde;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  outline: none;
  padding: 0;
}

.iconButton {
  margin-left: 15px;
  border: none;
  outline: none;
  width: 35px;
  height: 35px;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.frequencyText {
  margin-right: 4px;
  font-size: 16px;
}
