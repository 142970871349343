#settings {
  margin: 0;
  padding: 0;
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
}
#settings .container {
  margin: 0;
  padding: 0;
  /* border: 1px solid black; */
  height: 100%;
}
#settings .container .row {
  margin: 0;
  padding: 0;
  /* border: 1px solid black; */
  height: 100%;
  display: flex;
  flex-direction: column;
}

#settings .container .row .col-3 {
  margin: 0;
  padding: 1.5% 2.5% 0% 2.5%;
  width: 100%;
  height: 13.5%;
  /* border: 1px solid red; */
}
.settings-nav {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #cfcfcf;
  /* border: 1px solid blue; */
}
.settings-ul {
  /* border: 1px solid red; */
  margin: 0;
  padding: 0;
  width: 25%;
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: row;
}

.settings-ul li {
  /* border: 1px solid green; */
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  /* color: #737373; */
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: 200ms;
}
.settings-ul li:hover {
  color: black;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: rgb(152 144 144 / 8%);
  /* identical to box height */

  /* border-bottom: 2px solid #3a86ff; */
}
.active {
  color: #3a86ff;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  border-bottom: 2px solid #3a86ff;
}

#settings .container .row .col-9 {
  /* border: 1px solid green; */
  margin: 0;
  padding: 0;
  height: 86.5%;
  width: 100%;
}
