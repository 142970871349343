.container-fluid {
  /*   */
  height: 100%;
  width: 100%;
}
/* #Login .col {
  border: 3px red solid;
} */
#Login .row {
  height: 100%;
}
#Login {
  height: 100vh;
}
#Login .col:nth-child(1) {
  padding: 0;
}
.login {
  /* display: grid; */
  background: #ffffff;
  height: 100%;
  width: 100%;
}

/* form{
  height: 100%;
  top: 200px;
} */
.rectangle {
  /* grid-row: 1/3;
  grid-column: 1/2; */
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  background: rgba(58, 134, 255, 0.08);
}
.illustartion {
  height: 50%;
  width: 90%;
}
.main {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 40px;
}

.main1 {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 40px;
  /* border:1px solid red; */
  padding-top: 90px;
}

.accountRequest {
  /* border: 1px solid red; */
  width: 90%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 16px !important;
  font-family: "Asap" !important;
  font-weight: 600;
  color: #3a86ff;
  cursor: pointer;
}

.logo {
  /* Ceplr logo pnf-02 1 */
  /* height: 10%!important;
  width: 25%!important;
  margin-left: 2%; */
  /* border: 1px solid red; */
  width: 28%;
}

.username {
  /* Username */
  margin-left: 2.8%;
  margin-top: 3.5%;
  margin-bottom: -0.8%;
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  color: #767676;
}
.confirmUsername {
  /* Username */
  margin-left: 3%;
  /* margin-top: 2.5%; */
  margin-bottom: -1%;
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  color: #767676;

  color: #767676;
}

.requestName {
  /* Username */
  margin-left: 3%;
  /* margin-top: %; */
  /* margin-bottom: -1.5%; */
  font-family: Asap;
  font-style: normal;
  font-weight: 600;

  font-size: 16px;
  line-height: 22px;

  color: #767676;
}
.user_input {
  height: auto;
  width: 80%;
  margin-top: 2.8%;
  margin-left: 2.5%;
  font-size: 14px;
  background: #ffffff;
  border: 2px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;
}
.req_Name {
  height: auto;
  width: 80%;
  margin-top: 1%;
  margin-left: 2.5%;
  font-size: 14px;
  background: #ffffff;
  border: 2px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;
}
.loginPassword {
  width: 93%;
  height: 100%;
  border: none;
  font-size: 16px;
}
.error_req_name {
  height: auto;
  width: 80%;
  /* margin-top: -1%; */
  margin-left: 2.5%;
  font-size: 16px;
  background: #ffffff;
  /* border: 1px solid #bdbdbd; */
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;
  border: 2px solid rgb(201, 5, 5);
}
.password {
  margin-bottom: 1%;
  margin-left: 0.7%;
  margin-top: -3%;
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 15px;
  /* identical to box height */

  color: #767676;
}
.confirmPassword {
  /* margin-bottom: 1%; */
  margin-bottom: 1%;
  margin-left: 0.7%;
  margin-top: -3%;
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 15px;
  /* identical to box height */

  color: #767676;
}
.user_password {
  /*user password*/

  height: auto;
  width: 80%;
  margin-top: -1%;
  margin-left: 2.5%;
  font-size: 16px;
  background: #ffffff;
  border: 2px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

input:focus {
  outline: none !important;
  /* border-color: #719ECE; */
}

.forgot_password {
  /* Forget Password? */
  /* border: 1px solid green; */
  width: 80%;
  height: 4%;
  margin-left: 2%;
  margin-top: -2%;
  margin-bottom: 3%;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  color: #767676;
}
.log_in_button {
  width: 80%;
  height: 7%;
  margin-left: 2.5%;
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  cursor: pointer;
  border: 1px solid #3a86ff;
  /* identical to box height */

  color: #ffffff;

  background: #3a86ff;
  border-radius: 5px;
  transition: 550ms;
}
.log_in_buttonConfirm {
  width: 80%;
  height: 7%;
  margin-top: 2.9%;
  margin-left: 2.5%;
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  cursor: pointer;
  border: 1px solid #3a86ff;
  /* identical to box height */

  color: #ffffff;

  background: #3a86ff;
  border-radius: 5px;
  transition: 550ms;
}
.log_in_button:hover {
  /* color: #3a86ff; */
  background: #6f9feb;
}

.log_in_buttonConfirm:hover {
  /* color: #3a86ff; */
  background: #6f9feb;
}

.invalid {
  height: auto;
  width: 80%;
  margin-top: -1%;
  margin-left: 2.5%;
  font-size: 16px;
  background: #ffffff;
  /* border: 1px solid #bdbdbd; */
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;
  border: 2px solid rgb(201, 5, 5);
}

.user_Invalidinput {
  height: auto;
  width: 80%;
  margin-top: 2.5%;
  margin-left: 2.5%;
  font-size: 16px;
  background: #ffffff;
  border: 2px solid rgb(201, 5, 5);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;
}

.input1 {
  height: auto;
}
.input2 {
  height: auto;
  /* border: 1px solid blue; */
}
.input3 {
  /* border: 1px solid black; */
  /* height: auto; */
  height: 115px;
}
.input4 {
  /* border: 1px solid green; */
  /* height: auto; */
  height: 115px;
}
.input5 {
  height: 115px;
  /* border: 1px solid red; */
  /* margin-top: 10px; */
}

.validation-error {
  /* border: 1px solid black; */
  padding-top: 5px;
  height: 12px;
  /* width: 300px; */
  display: flex;
  align-items: center;
  /* position: relative; */
  margin-top: 0.4%;
  margin-left: 2.6%;
  color: rgb(201, 5, 5);
  font-size: 12px;
  font-weight: 400;
  /* height: fit-content;
  width: fit-content; */
  line-height: 0;
}
.goBack {
  display: flex;
  margin-left: 2.5%;
  /* padding-left: 20px; */
  /* border: 1px solid red; */
  /* margin-top: 20px; */
  /* padding-bottom: 20px; */
  width: 80%;
  font-size: 16px;
  font-family: Asap;
  color: #3a86ff;
  /* flex-direction: column; */
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
  margin-top: 20px;
}

.sendAgain {
  display: flex;
  padding-left: 20px;
  /* border: 1px solid red; */
  /* margin-top: 20px; */
  /* padding-bottom: 20px; */
  width: 80%;
  height: 22px;
  /* margin-top: 1%; */
  font-size: 15px;
  font-family: Asap;
  /* margin-top: 1px; */
  color: #0e0d0d;
  /* flex-direction: column; */
  justify-content: flex-end;
  font-weight: 400;
  /* padding-top: 5px */
  /* margin-top: -10px */
}

.resend-error {
  display: flex;
  padding-left: 20px;
  /* border: 1px solid red; */
  /* margin-top: 20px; */
  /* padding-bottom: 20px; */
  width: 90%;
  font-size: 13px;
  font-family: Asap;
  color: rgb(201, 5, 5);
  /* flex-direction: column; */
  justify-content: flex-end;
  font-weight: 400;
}

.resend-success {
  display: flex;
  padding-left: 20px;
  /* border: 1px solid red; */
  /* margin-top: 20px; */
  /* padding-bottom: 20px; */
  width: 90%;
  font-size: 13px;
  font-family: Asap;
  color: green;
  /* flex-direction: column; */
  justify-content: flex-end;
  font-weight: 600;
}

.validation-success {
  /* border: 1px solid black; */
  padding-top: 5px;
  /* position: relative; */
  margin-top: 1%;
  margin-left: 2.6%;
  color: green;
  font-size: 12px;
  font-weight: 600;
  /* height: fit-content;
  width: fit-content; */
  line-height: 0;
}
.login_err {
  margin-left: 0%;
  margin-top: 2%;
  height: 85px;
}

#error {
  position: relative;
  /* width: 90%; */
  font-size: 12px;
  font-weight: 600;
  margin-top: 1%;
  margin-left: 2.5%;
  width: 80%;
  height: 70%;
  /* margin-left: -14px;   */
}
