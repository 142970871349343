#dashboard {
  margin: 0;

  padding: 0;
  /* border: 3px   solid red; */
  width: 100%;
  height: 100%;
}
#dashboard .container-fluid {
  margin: 0;
  padding: 0;
  /* width: 100%;
  height: 100%; */
  /* border: 1px solid red; */
}
#dashboard .row-1 {
  height: 9%;
  width: 101%;
  padding: 0%;
  margin: 0%;
  /* border: 0.5px solid greenyellow; */
  /* position: sticky; */
}
#dashboard .row:nth-child(2) {
  /* border: 5px solid blue; */
  height: 91%;
}
#dashboard .col:nth-child(1) {
  /* border: 0.5px solid black; */
  /* position: sticky; */
  width: 16.5%;
  height: 100%;
  padding: 0;
  margin-left: 0.5%;
}
#dashboard .col:nth-child(2) {
  width: 82.5%;
  height: 97.9%;
  margin: 0;
  padding: 0;
  /* border: 2px solid rgb(158, 160, 158); */
}
