* {
  margin: 0;
  padding: 0;
  font-family: Asap;
  font-style: normal;
  overflow-wrap: break-word;
}
#app {
  font-family: Asap;
  height: 100%;
  position: absolute;
  width: 99%;
  margin: 0;
  padding: 0;
  /* border: 0.5px blue solid; */
}
select {
  font-family: Asap;
  font-size: 14px;
  border: none;
  width: 100%;
  background: white;
}
