.model_header_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.model_header_title {
  margin: 0;
  padding: 0;
  font-size: 18px;
  margin-left: 10px;
  font-weight: 600;
  color: #313131;
}

.main_header {
  width: 100%;
  height: 100%;
}

.main_title {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 600;
  color: #424242;
}

.row_container {
  width: 100%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  padding: 12px;
  border-radius: 10px;
  background-color: rgb(255, 251, 246);
}

.heading_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

.bullet {
  margin: 0;
  padding: 0;
  font-size: 7px;
}

.heading_text {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  color: #242424;
}

.value_title {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #797979;
}

.value_subtitle {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  color: #535353;
}

.column_container {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
}

.column_container_div {
  margin-top: 12px;
  padding: 12px;
  border-radius: 10px;
  background-color: rgb(255, 250, 244);
}

.column_container_div_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.column_container_heading {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  color: #242424;
}

.column_container_button {
  margin: 0;
  padding: 0;
  margin-left: 15px;
  font-size: 12px;
  color: #3a86ff;
  cursor: pointer;
}

.salary_date_div {
  display: flex;
  flex-wrap: wrap;
}

.salary_date_div_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
}

.mdl {
  display: block !important;
}

.mdl-dlg {
  overflow-y: initial !important;
}
.mdl_body {
  height: 90vh;
  overflow-y: auto;
}
.no_data_found {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.no_data_found div {
  max-width: 80%; 
  padding: 20px; 
  font-size: 1.5em; 
  color: #333; 
}
