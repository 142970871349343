.topbar_container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: sticky;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 2px rgba(248, 251, 255, 0.6);
}

.topbar_container_me {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: sticky;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(136, 136, 136, 0.25);
}

.topbar_logo {
  width: 15%;
}

.topbar_stage_container {
  margin-right: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fadeMe {
  opacity:    0.5; 
  background: #fff; 
  width:      100%;
  height:     100%; 
  z-index:    10;
  top:        0; 
  left:       0; 
  position:   fixed; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.topbar_stage_dev {
  margin: 0;
  padding: 0;
  font-family: Asap;
  font-size: 15px;
  font-weight: 600;
  margin-right: 13px;
}

.topbar_stage_prod {
  margin: 0;
  padding: 0;
  font-family: Asap;
  font-size: 15px;
  font-weight: 600;
  margin-left: 13px;
}
