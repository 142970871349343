#delete {
  cursor: pointer;
  color: rgb(8, 8, 8);
}

.modal-header {
  background: #f3f2f2;
  height: 10%;
}

.title {
  align-items: center;
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 0%;
}

.indexTitle {
  padding-left: 3%;
  margin: 0;
  margin-top: 1.5%;

  background: #f0f0f0;
}

.indexTitle1 {
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;

  color: #1f1f1f;
}

#modal-title {
  margin: 0;
  margin-top: 1.5%;
  margin-left: 7%;
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 18px; */

  color: #1f1f1f;
}
.modal-p .value {
  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  width: 50%;
  color: #464646;
}

.modal-p p {
  margin-top: 0;
  justify-content: space-between;
  display: flex;
  margin-bottom: 1rem;
  width: 85%;
}

.modal-p .attr {
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  /* font-weight: normal; */
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */
  color: #1f1f1f;
}

#consent_template {
  margin: 0;
  padding: 0;
  /* border: 1px solid green; */
  height: 100%;
  width: 100%;
  overflow: hidden;
  /* scrollbar-width: 0px; */
  padding-right: 50px;
}
#consent_template .container {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  /* border: 1px solid red; */
}
#consent_template .row:nth-child(1) {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  /* border: 1px solid blue; */

  display: flex;
  flex-direction: row;
  /* scrollbar-width: 0px; */
}

#consent_template .col:last-child(1) {
  background-color: #3a86ff;
}

#consent_template .col-4 {
  width: 100%;
  height: 13%;
  padding: 0;
  margin-top: 1%;
  /* border: 2px red solid; */
}

#consent_template .template_header {
  margin-top: 1%;
  margin-left: 1.5%;
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 39px;
  color: #42474f;
}
#consent_template .template_content {
  margin-left: 1.6%;
  margin-top: 0.8%;
  font-family: Asap;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  /* line-height: 22px; */
  width: 60%;
  /* identical to box height */

  color: #000000;
}
#consent_template .new_template {
  height: 50%;
  /* border: 0.5px solid blue; */
  width: 17%;

  margin-left: 83%;
  margin-top: -5%;
  margin-bottom: 5px;
}
#consent_template .add_button {
  height: 100%;
  width: 100%;
  padding: 0%;
  border-radius: 3px;
  padding-top: 1.5%;
  font-family: asap;
  font-style: normal;
  border-radius: 3px;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  border: 0px solid #3a86ff;
  color: #ffffff;
  background: #3a86ff;

  /* border:0.5px solid #3a86ff; */
}
#consent_template .add {
  height: 60%;
  width: 15%;
  padding-bottom: 1%;
  /* margin-left: -7%; */
  /* margin-right: 10px; */
}

#consent_template .col-8 {
  /* border: black 2px solid; */
  margin-top: 1%;
  margin-left: 1.5%;
  padding: 0;
  width: 98.5%;
  height: 80%;
  overflow: auto;
  overflow-y: hidden;
}

#consent_template .col-8::-webkit-scrollbar {
  display: none;
}
#consent_template .ctTable {
  /* border: 1px solid black; */
  height: 100%;
  width: 100%;
  overflow: scroll;
}
#consent_template .ctTable::-webkit-scrollbar {
  display: none;
}

#consent_template table {
  /* border:0.5px solid #DCDCDC; */
  height: auto;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
}

#consent_template th {
  height: 67px;
  position: sticky;
  top: 0;
  background: #ffff;
  vertical-align: middle;
  z-index: 1;
  padding: 0;
  /* padding-bottom: 2%; */
  padding-left: 1%;
  border: 0.5px solid #e6e6e6;
  justify-content: center;

  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}
#consent_template th:nth-child(1) {
  width: 18%;
  /* border: 1px solid black; */
}
#consent_template th:nth-child(7) {
  width: 21%;
  /* border: 1px solid black; */
}
#consent_template #preloader {
  /* border: 1px solid black; */
  /* height: 0%;
  width: 50%; */
  margin-left: 250%;
  margin-top: 70%;
}

#consent_template tbody tr td {
  height: 80px;
  vertical-align: middle;
  padding-left: 1%;
  border: 0.5px solid #e6e6e6;
  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  font-size: 14.5px;

  line-height: 125%;
  /* identical to box height */

  color: #1c1c1c;
}

#consent_template tr:nth-child(1) {
  background: rgb(255, 255, 255);
}
#consent_template .transNull {
  margin: 0;
  padding: 0;
}

#consent_template td:nth-child(7) {
  padding: 0;
  /* padding-left: 1.3%; */
  /* border: 1px solid black; */
}

#consent_template .ctView {
  height: 48%;
  width: 30%;
  background: #ffff;
  border: 1px solid #3a86ff;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Asap;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  color: #3a86ff;
  transition: linear 0.3s;
}

#consent_template .ctView:hover {
  color: #ffffff;
  background: #3a86ff;
}

#consent_template .add_button:hover {
  color: #ffffff;
  background: #3a86ff;
}

#consent_template .ctEdit,
.ctDelete {
  margin-left: 5%;
  border: 0;
  border-radius: 50%;
}

#consent_template .ctSend {
  margin-left: 5%;
  border: 0;
  background-color: transparent;
}

#consent_template .pdfSend {
  margin-left: 5%;
  border: 0;
  background-color: white;
  height: 32px;
  width: 32px;
  border-radius: 4px;
}

#consent_template .viewConfig {
  /* margin-left: 5%; */
  border: 0;
  background-color: white;
  height: 32px;
  width: 32px;
  border-radius: 4px;
}

.iconButton {
  margin-left: 15px;
  border: none;
  outline: none;
  width: 35px;
  height: 35px;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
