.dropdown {
  height: 100%;
  padding: 0;
  margin: 0;
  width: 100%;
  /* border: 1px solid #3e3e3e; */
  background: #ffffff;
  display: flex;
  align-items: center;
  font-family: Asap;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #1f1f1f;
  box-sizing: border-box;
  border-radius: 2px;
  padding-left: 5%;
  cursor: pointer;

  position: relative;
}
.dropdown ::-webkit-scrollbar {
  display: none;
}
.header {
  /* border: 1px solid black; */
  margin: 0;
  padding: 0;
  padding-left: 2.5%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.doropdownHeader {
  margin: 0;
  padding: 0;
  padding-top: 4.8%;
  display: flex;
  flex-direction: row;
  /*align-items: center; */
  /* vertical-align: middle; */
  width: 85%;
  height: 100%;
  overflow-y: scroll;
  /* inline-size: 150px; */
  /* overflow: hidden;
  text-overflow: ellipsis; */
  /* border: 1px blue solid; */
  scrollbar-width: none;
}
.ArrowDropDown {
  /* border: red 1px solid; */
  height: 100%;
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img1 {
  /* border: red 1px solid; */
  height: 60%;
  width: 60%;
  display: flex;
  /* justify-content: center;
  align-items: center; */
}
.img2 {
  /* border: red 1px solid; */
  margin: 0;
  padding: 0;
  /* margin-left: -100%; */
  height: 40%;
  width: 40%;
  display: flex;
  /* justify-content: center;
  align-items: center; */
}

.dropdown-content {
  top: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  left: 0;
  border: 1px solid black;
  box-shadow: 0px 4px 4px 6px rgba(141, 141, 141, 0.36);
  position: absolute;
  background: white;
  /* overflow-y: scroll; */
  scrollbar-width: none;
  /* height: max-content; */
}
.dropdown-content1 {
  top: 100%;
  /* height: 30px; */
  margin: 0;
  padding: 0;
  width: 100%;
  left: 0;
  border: 1px solid black;
  box-shadow: 0px 4px 4px 6px rgba(141, 141, 141, 0.36);
  position: absolute;
  background: white;
  overflow-y: scroll;
  scrollbar-width: none;
}
.dropdown-ul1 {
  margin: 0;
  /* overflow-y: scroll; */
  padding: 0;
  display: flex;
  width: 100%;
  height: 75px;
  flex-direction: column;
  list-style-type: none;
  /* justify-content: center; */
  /* border: #1f1f1f 1px solid; */
}
.dropdown-ul {
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  height: 100px;
  flex-direction: column;
  list-style-type: none;
  justify-content: flex-start;
  /* border: #1f1f1f 1px solid; */
}
.dropdown-label {
  margin: 0;
  padding: 0;
  /* border: 1px solid red; */
  height: 33.3%;
}
.dropdown-list {
  /* border: 1px solid black; */
  margin: 0;
  height: 100%;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;
}

.type-name {
  /* border: 1px black solid; */
  height: 100%;
  width: 75%;
  overflow-wrap: break-word;
  padding: 0;
  margin: 0;
  padding-left: 5%;
  display: flex;
  align-items: center;
}
.dropdown-checkbox {
  /* border: 1px black solid; */
  height: 100%;
  width: 25%;
  padding: 0;
  margin: 0;
  /* padding-left: 5%; */
  display: flex;
  align-items: center;
  justify-content: center;
}
