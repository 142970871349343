
  .backGroundContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.deleteBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.deleteDiv {
  background: #f5dee2;
  display: flex;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  justify-content: center;
  align-items: center;
}

.deleteConfirmation {
  font-family: "Asap";
  font-weight: 500;
  font-size: 17px;
  color: #000;
  margin-top: 15px;
}

.buttonDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.yesButton {
  background-color: #3a86ff;
  border-radius: 4px;
  color: #fff;
  border: 1px solid #3a86ff;
  width: 120px;
    height: 46px;
    cursor: pointer;
}

.noButton {
  background-color: transparent;
  color: #722121;
  border: 1px solid #722121;
  border-radius: 4px;
  margin-left: 10px;
  width: 120px;
    height: 46px;
    cursor: pointer;
}

.backGroundContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}